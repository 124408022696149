import React, { useEffect, useState, useRef } from 'react';
import styles from './index.module.scss';
import { useMediaContext } from '@/contexts/media';

const AudioPlayer = () => {
  const { state } = useMediaContext();
  const audioRef = useRef(state.audio.player);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    setInterval(() => setCurrentTime(audioRef.current.currentTime));
  }, []);

  return (
    <div className={styles.audioPlayer}>
      <label className={styles.title}>{state.audio.title}</label>
      <div className={styles.rangeOuter}>
        <input
          className={styles.range}
          type="range"
          min="0"
          max={audioRef.current.duration || 0}
          value={currentTime}
          onChange={() => {}}
        />
      </div>
    </div>
  );
};

export default AudioPlayer;
