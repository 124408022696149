import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLiveQuery } from 'dexie-react-hooks';
import 'lazysizes';
import styles from './index.module.scss';
import { useMediaContext } from '@/contexts/media';
import { db } from '@/models/db';
import { IDiscography, ITrack, IPage, IImage } from '../../types/type';
import { data } from './data';
import pageConf from './page.json';
import PageContainer from '@/components/PageContainer';
import PageContent from '@/components/PageContent';

type ILink = IImage & { name: string; url: string };

const page: IPage = pageConf;
const links: ILink[] = pageConf.links;

const Discography = () => {
  return (
    <PageContainer {...page}>
      <Helmet>
        <title>{page.title.text}</title>
        <meta name="description" content={page.description} />
      </Helmet>
      <PageContent title={page.title}>
        <div className={styles.discoList}>
          {data.map((d) => (
            <DiscographyItem key={d.title} discography={d} />
          ))}
        </div>
        <ul className={styles.linkList}>
          {links.map((link: ILink) => (
            <li key={link.name} className={styles.linkItem}>
              <a
                className={styles.link}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source type="image/webp" data-srcset={link.webp} />
                  <img
                    data-src={link.original}
                    className="lazyload"
                    alt={link.name}
                  />
                </picture>
              </a>
            </li>
          ))}
        </ul>
      </PageContent>
    </PageContainer>
  );
};

const DiscographyItem = ({ discography }: { discography: IDiscography }) => {
  const { state } = useMediaContext();
  const thumbnail = useLiveQuery(() =>
    db.media.get({
      src: state.canWebp
        ? discography.thumbnail.webp
        : discography.thumbnail.original,
    })
  );

  return (
    <div>
      <div className={styles.summary}>
        <div>
          <span className={styles.title}>{discography.title}</span>
          <span className={styles.subTitle}>{discography.subTitle}</span>
        </div>
        <picture>
          <source data-srcset={thumbnail?.cache} />
          <source
            type="image/webp"
            data-srcset={discography.thumbnail.webp}
          />
          <img
            data-src={discography.thumbnail.original}
            className={`${styles.img} lazyload`}
            alt={discography.title}
          />
        </picture>
      </div>
      <ul className={styles.list}>
        {discography.tracks.map((track: ITrack) => (
          <li key={track.title} className={styles.listItem}>
            <p className={styles.trackTitle}>{track.title}</p>
            <p className={styles.textOuter}>
              {track?.lyricist && <span className={styles.text}>作詞：{track.lyricist}</span>}
              <span className={styles.text}>作曲：{track.composer}</span>
              <span className={styles.text}>編曲：{track.arranger}</span>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Discography;
