import React, { useRef, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { ILocationState, IRouter } from './types/type';
import { useLocationContext } from '@/contexts/location';
import { useMediaContext } from '@/contexts/media';
import '@/styles/transition.scss';
import Home from '@/pages/home';
import Biography from '@/pages/biography';
import Discography from '@/pages/discography';
import Contact from '@/pages/contact';
import EmptyRoom from '@/pages/empty-room';
import DefaultLayout from '@/layouts/Default';

const config: IRouter[] = [
  { path: '/', component: Home },
  { path: '/discography', component: Discography },
  { path: '/empty-room', component: EmptyRoom },
  { path: '/contact', component: Contact },
  { path: '/biography', component: Biography },
];

const TransitionContainer = ({ children, ...props }: CSSTransitionProps) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition nodeRef={nodeRef} {...props}>
      <div ref={nodeRef}>{children}</div>
    </CSSTransition>
  );
};

const TransitionSwitch = withRouter(
  ({ location }: RouteComponentProps<{}, {}, ILocationState>) => {
    const { setPath } = useLocationContext();
    useEffect(() => setPath(location.pathname));

    const { state } = useMediaContext();
    if (state.isLanding && location.pathname !== '/') {
      return <Redirect to="/" />;
    }

    const direction = location.state?.direction;
    const classNames = direction ? `slide slide-${direction}` : 'slide';

    return (
      <TransitionGroup>
        <TransitionContainer
          key={location.key}
          classNames={classNames}
          // 前のページを削除する時間
          timeout={1000}
        >
          <Switch location={location}>
            {config.map((page) => (
              <Route
                exact
                key={page.path}
                path={page.path}
                component={page.component}
              />
            ))}
          </Switch>
        </TransitionContainer>
      </TransitionGroup>
    );
  }
);

function App() {
  return (
    <DefaultLayout>
      <Router>
        <TransitionSwitch />
      </Router>
    </DefaultLayout>
  );
}

export default App;
