import React, { useState, createContext, useContext } from 'react';
import { ILocationContext } from '../types/type';

const initialPath = '/';

export const LocationContext = createContext<ILocationContext>({
  path: initialPath,
  setPath: (path: string) => {},
});

const LocationProvider = ({ children }: any) => {
  const [path, setPath] = useState(initialPath);
  return (
    <LocationContext.Provider value={{ path, setPath }}>
      {children}
    </LocationContext.Provider>
  );
};

const useLocationContext = () => useContext(LocationContext);

export { LocationProvider, useLocationContext };
