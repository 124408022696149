import React from 'react';
import styles from './index.module.scss';
import { data } from './data';

const SnsLinkList = () => {
  return (
    <ul className={styles.list}>
      {data.map((l) => (
        <li key={l.name} className={styles.listItem}>
          <a
            href={l.url}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <picture>
              <source type="image/webp" data-srcset={l.webp} />
              <img data-src={l.original} className="lazyload" alt={l.name} />
            </picture>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SnsLinkList;
