import React from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { IPageContent } from './../../types/type';
import styles from './index.module.scss';
import { db } from '@/models/db';

const PageContent = ({ title, children, isOriginalContent }: IPageContent) => {
  const svg = useLiveQuery(() => db.media.get({ src: title.svg }));

  return (
    <div className={styles.pageContent}>
      <h1 className={styles.title}>
        <picture>
          <source data-srcset={svg?.cache} />
          <img
            className={`${styles.titleImg} ${
              styles['_' + title.text]
            } lazyload`}
            data-src={title.svg}
            alt={title.text}
          />
        </picture>
      </h1>
      {isOriginalContent === true ? (
        children
      ) : (
        <div className={styles.content}>{children}</div>
      )}
    </div>
  );
};

export default PageContent;
