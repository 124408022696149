import React, { useState, createContext, useContext } from 'react';
import { IMediaContent } from '../types/type';
import Browser from '@/utils/browser';

const initPlayer = (): HTMLAudioElement => {
  const audio = new Audio();
  audio.preload = 'auto';
  audio.src = '/tokyo-tower.mp3';
  audio.loop = true;
  audio.volume = 0.5;
  audio.load();
  return audio;
};

const initialState = {
  audio: {
    title: '東京タワー',
    player: initPlayer(),
  },
  isLanding: true,
  canWebp: Browser.isSupportWebp(),
  canWebm: Browser.isSupportWebm(),
};

export const MediaContext = createContext<IMediaContent>({
  state: { ...initialState },
  setState: () => {},
});

const MediaProvider = ({ children }: any) => {
  const [state, setState] = useState({ ...initialState });
  return (
    <MediaContext.Provider value={{ state, setState }}>
      {children}
    </MediaContext.Provider>
  );
};

const useMediaContext = () => useContext(MediaContext);

export { MediaProvider, useMediaContext };
