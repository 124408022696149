import React, { useEffect, useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import 'lazysizes';
import { IImage } from '../../types/type';
import styles from './index.module.scss';
import { useMediaContext } from '@/contexts/media';
import { db } from '@/models/db';

const SCALE_DELAY = 500;

const ScaleBackgroundImg = ({ ...src }: IImage) => {
  const { state } = useMediaContext();
  const [isActive, setActive] = useState(false);
  const image = useLiveQuery(() =>
    db.media.get({ src: state.canWebp ? src.webp : src.original })
  );

  useEffect(() => {
    if (!state.isLanding) setTimeout(() => setActive(true), SCALE_DELAY);
  }, [state.isLanding]);

  return (
    <div className={styles.scaleBackgroundImg}>
      <picture>
        {!state.isLanding && <source data-srcset={image?.cache} />}
        <source type="image/webp" data-srcset={src.webp} />
        <img
          className={`${styles.img} ${isActive ? styles._active : ''} lazyload`}
          data-src={src.original}
          alt=""
        />
      </picture>
    </div>
  );
};

export default ScaleBackgroundImg;
