import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { ILink } from '../../types/type';
import styles from './index.module.scss';

const Link = ({ path, name, direction }: ILink) => {
  return (
    <ReactLink
      className={`${styles.link} ${styles['_' + direction]}`}
      to={{ pathname: path, state: { direction } }}
    >
      <span className={styles.text}>{name}</span>
    </ReactLink>
  );
};

export default Link;
