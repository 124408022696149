import Dexie from 'dexie';
import { ICache } from '../types/type';

const VERSION = 1;

export class CacheDB extends Dexie {
  media: Dexie.Table<ICache, string>;

  constructor() {
    super('mediaDB');

    this.version(VERSION).stores({ media: 'src, cache' });
    this.media = this.table('media');
  }

  async store(src: string) {
    const res = await fetch(src);
    const blob = await res.blob();
    await this.media.put({ src, cache: URL.createObjectURL(blob) });
  }
}

export const db = new CacheDB();
