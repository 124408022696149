export default class Browser {
  public static userAgent(): string {
    return window.navigator.userAgent.toLowerCase();
  }

  public static isIE(): boolean {
    return (
      Browser.userAgent().indexOf('msie') !== -1 ||
      Browser.userAgent().indexOf('trident') !== -1
    );
  }

  public static isEdge(): boolean {
    return Browser.userAgent().indexOf('edg') !== -1;
  }

  static isSupportWebp = (): boolean => {
    const canvas = document.createElement('canvas');
    if (!!(canvas.getContext && canvas.getContext('2d'))) {
      return canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
  };

  static isSupportWebm = (): boolean => {
    const video = document.createElement('video');
    return video.canPlayType('video/webm; codecs="vp8, vorbis"') === 'probably';
  };
}
