import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLiveQuery } from 'dexie-react-hooks';
import { IEmptyRoom, IPage } from '../../types/type';
import dataConf from './data.json';
import pageConf from './page.json';
import styles from './index.module.scss';
import Link from '@/components/Link';
import { useMediaContext } from '@/contexts/media';
import { db } from '@/models/db';

const data: IEmptyRoom = dataConf;
const page: IPage = pageConf;

const EmptyRoom = () => {
  const { state } = useMediaContext();
  const pcImg = useLiveQuery(() =>
    db.media.get({
      src: state.canWebp
        ? data.backgroundImg.pc.webp
        : data.backgroundImg.pc.original,
    })
  );
  const spImg = useLiveQuery(() =>
    db.media.get({
      src: state.canWebp
        ? data.backgroundImg.sp.webp
        : data.backgroundImg.sp.original,
    })
  );

  return (
    <div className={styles.emptyRoom}>
      <Helmet>
        <title>{page.title.text}</title>
        <meta name="description" content={page.description} />
      </Helmet>
      <picture className={styles.imgOuter}>
        <source data-srcset={pcImg?.cache} />
        <source type="image/webp" data-srcset={data.backgroundImg.pc.webp} />
        <img
          className={`${styles.img} lazyload`}
          data-src={data.backgroundImg.pc.original}
          alt=""
        />
      </picture>
      <picture className={`${styles.imgOuter} ${styles._sm}`}>
        <source data-srcset={spImg?.cache} />
        <source type="image/webp" data-srcset={data.backgroundImg.sp.webp} />
        <img
          className={`${styles.img} lazyload`}
          data-src={data.backgroundImg.sp.original}
          alt=""
        />
      </picture>
      <div className={styles.link}>
        <Link {...page.transition.left} direction="left" />
      </div>
    </div>
  );
};

export default EmptyRoom;
