import React, { useState } from 'react';
import { useCountUp } from 'react-countup';
import { useMediaContext } from '@/contexts/media';
import { cache } from './cache';
import styles from './index.module.scss';

const easeInCirc = (t: number, b: number, c: number, d: number): number => {
  return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b;
};

const Landing = () => {
  const { state, setState } = useMediaContext();
  const [isLoading, setLoading] = useState(false);
  const { countUp, start } = useCountUp({
    end: 100,
    duration: 4,
    startOnMount: false,
    useEasing: true,
    easingFn: easeInCirc,
    onEnd: () => handleFadeOut(),
  });

  const handleLoading = (isMute: boolean) => {
    state.audio.player.muted = isMute;
    state.audio.player.play();
    setState(state);

    cache(state);

    setLoading(true);
    start();
  };

  const handleFadeOut = () => {
    setTimeout(() => setState({ ...state, isLanding: false }), 1000);
  };

  return (
    <div
      className={`${styles.landing} ${state.isLanding ? styles._active : ''} ${
        isLoading ? styles._loading : ''
      }`}
    >
      <div
        className={`${styles.switchOuter} ${isLoading ? '' : styles._active}`}
      >
        <button
          type="button"
          className={styles.switch}
          onClick={() => handleLoading(false)}
        >
          sound ON
        </button>
        <div className={styles.separator} />
        <button
          type="button"
          className={styles.switch}
          onClick={() => handleLoading(true)}
        >
          sound OFF
        </button>
      </div>
      <div
        className={`${styles.countOuter} ${isLoading ? styles._active : ''}`}
      >
        <span className={styles.count}>{countUp}</span>
        <span className={styles.percent}> %</span>
      </div>
    </div>
  );
};

export default Landing;
