import debounce from 'lodash.debounce';

const DEBOUNCE_DELAY = 300;
const DEBOUNCE_MAX_DELAY = 1000;

export const setSize = debounce(
  () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    document.body.setAttribute(
      'style',
      `--window-width:${width}px; --window-height:${height}px; --height-ratio: ${
        Math.round((width / height) * 100) / 100
      }`
    );
  },
  DEBOUNCE_DELAY,
  { maxWait: DEBOUNCE_MAX_DELAY }
);
