import { IDiscography } from '../../types/type';

export const data: IDiscography[] = [
  {
    thumbnail: {
      webp: '/biography/thumbnail.webp',
      original: '/biography/thumbnail.jpg',
    },
    title: 'はじめ',
    subTitle: 'カジミナミ first mini album',
    tracks: [
      {
        title: '東京タワー',
        lyricist: 'カジミナミ',
        composer: '黒須チヒロ',
        arranger: '星勝',
      },
      {
        title: '紫色の雨〜femme fatale〜',
        lyricist: 'カジミナミ',
        composer: '星勝',
        arranger: '星勝',
      },
      {
        title: '青の月',
        lyricist: 'カジミナミ',
        composer: '黒須チヒロ',
        arranger: '松岡モトキ',
      },
      {
        title: 'dive...',
        lyricist: 'カジミナミ',
        composer: '黒須チヒロ',
        arranger: '松岡モトキ',
      },
      {
        title: 'silent',
        lyricist: 'カジミナミ',
        composer: '星勝',
        arranger: '星勝',
      },
    ],
  },
  {
    thumbnail: {
      webp: '/biography/thumbnail2.webp',
      original: '/biography/thumbnail2.jpg',
    },
    title: 'まして',
    subTitle: 'カジミナミ first mini album',
    tracks: [
      {
        title: '東京タワー (Instrumental)',
        composer: '黒須チヒロ',
        arranger: '星勝',
      },
      {
        title: 'silent (Instrumental)',
        composer: '星勝',
        arranger: '星勝',
      },
    ],
  },
] as const;
