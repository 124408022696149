import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from '@/App';
import Browser from '@/utils/browser';
import { setSize } from './utils/window';
import '@/styles/reset.scss';
import '@/styles/base.scss';
import '@/styles/ieMessage.scss';

const root = document.getElementById('root');

const insertIEMessage = () => {
  if (!root) return;

  root.insertAdjacentHTML(
    'afterbegin',
    `
    <div class="IEMessageOuter">
      <div>
        <p class="IEMessage">
          恐れ入りますが、このサイトはお使いのブラウザに対応していません。
          <br />
          以下のブラウザを推奨しております。
        </p>
        <a
          class="IEMessageLink"
          href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=ja"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Chromeをダウンロード
        </a>
      </div>
    </div>
  `
  );
};

if (Browser.isIE()) {
  insertIEMessage();
} else {
  setSize();
  window.addEventListener('resize', setSize);

  ReactDOM.render(
    <React.StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </React.StrictMode>,
    root
  );
}
