import React from 'react';
import { useMediaContext } from '@/contexts/media';
import styles from './index.module.scss';

const SoundSwitch = () => {
  const { state, setState } = useMediaContext();
  const isMute = state.audio.player.muted;

  const handleSound = (isMute: boolean) => {
    state.audio.player.muted = isMute;
    setState({ ...state });
  };

  return (
    <div className={styles.soundSwitch}>
      <button
        className={`${styles.button} ${isMute ? '' : styles._active}`}
        onClick={() => handleSound(false)}
        type="button"
      >
        ON
      </button>
      <span className={styles.separator}>/</span>
      <button
        className={`${styles.button} ${isMute ? styles._active : ''}`}
        onClick={() => handleSound(true)}
        type="button"
      >
        OFF
      </button>
    </div>
  );
};

export default SoundSwitch;
