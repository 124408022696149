import React from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { Helmet } from 'react-helmet-async';
import 'lazysizes';
import { IImage, IPage } from '../../types/type';
import styles from './index.module.scss';
import PageContainer from '@/components/PageContainer';
import PageContent from '@/components/PageContent';
import { useMediaContext } from '@/contexts/media';
import { db } from '@/models/db';
import data from './data.json';
import pageConf from './page.json';

const profile: IImage = data.profile;
const signature: IImage = data.signature;
const page: IPage = pageConf;

const Biography = () => {
  const { state } = useMediaContext();
  const profileImg = useLiveQuery(() =>
    db.media.get({ src: state.canWebp ? profile.webp : profile.original })
  );
  const signatureImg = useLiveQuery(() =>
    db.media.get({ src: state.canWebp ? signature.webp : signature.original })
  );

  return (
    <PageContainer {...page}>
      <Helmet>
        <title>{page.title.text}</title>
        <meta name="description" content={page.description} />
      </Helmet>
      <PageContent title={page.title} isOriginalContent={true}>
        <div className={styles.content}>
          <div className={styles.contentInner}>
            <picture>
              <source data-srcset={profileImg?.cache} />
              <source type="image/webp" data-srcset={profile.webp} />
              <img
                data-src={profile.original}
                className={`${styles.profileImg} lazyload`}
                alt="カジミナミ プロフィール"
              />
            </picture>
            <div className={styles.nameGroup}>
              <h2 className={styles.name}>カジミナミ</h2>
              <small className={styles.nameSub}>Minami Kaji</small>
            </div>
            <p className={styles.profileText}>
              幼い頃からひとり遊びが好きでした
              <br />
              <br />
              ふわふわひらひら
              <br />
              舞う蝶々を追いかけて
              <br />
              とまった先は可愛い花びら
              <br />
              上下する羽の動きに呼吸を合わせて
              <br />
              指先でそっと摘まみとったら
              <br />
              自由を欲しがって激しく身をよじる
              <br />
              <br />
              綺麗に揃ったその鱗粉が剥がれ落ち
              <br />
              傷んでゆくその姿に
              <br />
              とても恐くなって慌てて放す
              <br />
              <br />
              それは狂気じみた艶っぽさ
              <br />
              苦しみと悦びは表裏一体に
              <br />
              生命の脈動を刻んだ
              <br />
              <br />
              今も昨日のことのよう
            </p>
            <picture className={styles.signatureImgOuter}>
              <source data-srcset={signatureImg?.cache} />
              <source type="image/webp" data-srcset={signature.webp} />
              <img
                data-src={signature.original}
                className={`${styles.signatureImg} lazyload`}
                alt="カジミナミ"
              />
            </picture>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default Biography;
