import React from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { Helmet } from 'react-helmet-async';
import 'lazysizes';
import PageContainer from '@/components/PageContainer';
import { useMediaContext } from '@/contexts/media';
import { db } from '@/models/db';
import { IPage } from '../../types/type';
import pageConf from './page.json';
import styles from './index.module.scss';

const page: IPage = pageConf;

const Home = () => {
  const { state } = useMediaContext();
  const title = useLiveQuery(() => db.media.get({ src: page.title.svg }));

  return (
    <PageContainer {...page}>
      <Helmet>
        <title>{page.title.text}</title>
        <meta name="description" content={page.description} />
      </Helmet>
      <h1>
        <picture>
          {!state.isLanding && <source data-srcset={title?.cache} />}
          <img
            className={`${styles.titleImg} lazyload`}
            data-src={page.title.svg}
            width="300px"
            height="84px"
            alt={page.title.text}
          />
        </picture>
      </h1>
    </PageContainer>
  );
};

export default Home;
