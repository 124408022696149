import React from 'react';
import { LocationProvider } from '@/contexts/location';
import { MediaProvider } from '@/contexts/media';
import styles from './index.module.scss';
import AudioPlayer from '@/components/AudioPlayer';
import Landing from '@/components/Landing';
import PageMap from '@/components/PageMap';
import SoundSwitch from '@/components/SoundSwitch';
import SnsLinkList from '@/components/SnsLinkList';

const Default = (props: { children: JSX.Element }) => {
  return (
    <LocationProvider>
      <MediaProvider>
        {props.children}
        <div className={styles.audioPlayer}>
          <AudioPlayer />
        </div>
        <div className={styles.soundSwitch}>
          <SoundSwitch />
        </div>
        <div className={styles.pageMap}>
          <PageMap />
        </div>
        <div className={styles.snsOuter}>
          <SnsLinkList />
        </div>
        <Landing />
      </MediaProvider>
    </LocationProvider>
  );
};

export default Default;
