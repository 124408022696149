import React from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import map from 'lodash.map';
import { IPageContainer, DirectionTypes, ITransition } from '../../types/type';
import styles from './index.module.scss';
import Link from '@/components/Link';
import ScaleBackgroundImg from '@/components/ScaleBackgroundImg';
import { useMediaContext } from '@/contexts/media';
import { db } from '@/models/db';

const LinkGroup = (transition: ITransition) => {
  return map(transition, (props, key) => {
    const direction = key as DirectionTypes;
    if (props === undefined) return;
    return (
      <div key={direction} className={`${styles.link} ${styles[direction]}`}>
        <Link direction={direction} {...props} />
      </div>
    );
  });
};

const PageContainer = ({
  mainVideo,
  videoBackgroundImg,
  backgroundImg,
  transition,
  children,
}: IPageContainer) => {
  const { state } = useMediaContext();
  const video = useLiveQuery(() =>
    db.media.get({ src: state.canWebm ? mainVideo.webm : mainVideo.original })
  );
  const videoBackground = useLiveQuery(() =>
    db.media.get({
      src: state.canWebp
        ? videoBackgroundImg.webp
        : videoBackgroundImg.original,
    })
  );

  return (
    <div className={styles.pageContainer}>
      <picture>
        {!state.isLanding && <source data-srcset={videoBackground?.cache} />}
        <source type="image/webp" data-srcset={videoBackgroundImg.webp} />
        <img
          data-src={videoBackgroundImg.original}
          className={`${styles.videoBackgroundImg} lazyload`}
          alt=""
        />
      </picture>
      <video
        className={`${styles.video} lazyload`}
        controls={false}
        playsInline={true}
        preload="none"
        data-autoplay={true}
        data-poster={videoBackgroundImg.original}
        muted={true}
        loop={true}
      >
        {video?.cache && <source src={video.cache} />}
        <source type="video/webm" src={mainVideo.webm} />
        <source type="video/mp4" src={mainVideo.original} />
      </video>
      <div className={styles.inner}>
        {children}
        <ScaleBackgroundImg {...backgroundImg} />
        {LinkGroup(transition)}
      </div>
    </div>
  );
};

export default PageContainer;
