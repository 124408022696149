import React from 'react';
import { Helmet } from 'react-helmet-async';
import { IPage } from '../../types/type';
import styles from './index.module.scss';
import PageContainer from '@/components/PageContainer';
import PageContent from '@/components/PageContent';
import pageConf from './page.json';

const page: IPage = pageConf;

const Contact = () => (
  <PageContainer {...page}>
    <Helmet>
      <title>{page.title.text}</title>
      <meta name="description" content={page.description} />
    </Helmet>
    <PageContent title={page.title}>
      <form className={styles.form} action={env.default.contact.action}>
        <div>
          <label className={styles.label} htmlFor="name">
            名前 <span className={styles.required}>※</span>
          </label>
          <input
            id="name"
            className={styles.input}
            type="text"
            name={env.default.contact.name}
            placeholder="名前"
            required
          />
        </div>
        <div>
          <label className={styles.label} htmlFor="kana">
            カナ <span className={styles.required}>※</span>
          </label>
          <input
            id="kana"
            className={styles.input}
            type="text"
            name={env.default.contact.kana}
            placeholder="カナ"
            required
          />
        </div>
        <div>
          <label className={styles.label} htmlFor="email">
            e-mail <span className={styles.required}>※</span>
          </label>
          <input
            id="email"
            className={styles.input}
            type="email"
            name={env.default.contact.email}
            placeholder="sample@sample.com"
            required
          />
        </div>
        <div>
          <label className={styles.label} htmlFor="context">
            お問い合わせ内容 <span className={styles.required}>※</span>
          </label>
          <textarea
            id="context"
            className={styles.textarea}
            name={env.default.contact.context}
            placeholder="お問い合わせ内容"
            required
          />
        </div>
        <button className={styles.submit} type="submit" name="button">
          送信
        </button>
      </form>
    </PageContent>
  </PageContainer>
);

export default Contact;
