import React, { useEffect } from 'react';
import map from 'lodash.map';
import styles from './index.module.scss';
import { useLocationContext } from '@/contexts/location';
import data from './data.json';
import { IMap } from '../../types/type';

const getCellClassName = (map: IMap) => {
  const className: string[] = [styles.cell];
  if (map.path === null) className.push(styles._hidden);
  if (map.path === window.location.pathname) className.push(styles._current);
  return className.join(' ');
};

const PageMap = () => {
  const { path } = useLocationContext();
  useEffect(() => {}, [path]);

  return (
    <div>
      {map(data, (row: IMap[], rowIndex: number) => {
        return (
          <div key={rowIndex} className={styles.row}>
            {map(row, (column: IMap, columnIndex: number) => (
              <div
                key={`${rowIndex}-${columnIndex}`}
                className={getCellClassName(column)}
              >
                {map(column.direction, (_: boolean, direction: string) => (
                  <div
                    key={direction}
                    className={`${styles.pipe} ${styles['_' + direction]}`}
                  ></div>
                ))}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default PageMap;
