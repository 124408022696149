import { db } from '@/models/db';
import biographyData from '@/pages/biography/data.json';
import biography from '@/pages/biography/page.json';
import { data as discographyData } from '../../pages/discography/data';
import discography from '@/pages/discography/page.json';
import contact from '@/pages/contact/page.json';
import emptyRoom from '@/pages/empty-room/data.json';
import home from '@/pages/home/page.json';
import { IImage, IPage, IVideo } from '../../types/type';

const cacheImg = (state: any, image: IImage) => {
  if (state.canWebp) {
    db.store(image.webp);
  } else {
    db.store(image.original);
  }
};

const cacheVideo = (state: any, video: IVideo) => {
  if (state.canWebm) {
    db.store(video.webm);
  } else {
    db.store(video.original);
  }
};

export const cache = (state: any) => {
  const pages = [home, biography, discography, contact];
  pages.forEach((page: IPage) => {
    db.store(page.title.svg);
    cacheImg(state, page.backgroundImg);
    cacheImg(state, page.videoBackgroundImg);
    cacheVideo(state, page.mainVideo);
  });

  discographyData.forEach((discography) => {
    cacheImg(state, discography.thumbnail);
  });

  cacheImg(state, biographyData.profile);
  cacheImg(state, biographyData.signature);

  cacheImg(state, emptyRoom.backgroundImg.pc);
  cacheImg(state, emptyRoom.backgroundImg.sp);
};
