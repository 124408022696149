export const data = [
  {
    name: 'Youtube',
    url: 'https://www.youtube.com/channel/UCddxhdep5FDpeidETcI7ReQ',
    original: '/common/youtube.png',
    webp: '/common/youtube.webp',
  },
  // {
  //   "name": "Instagram",
  //   "url": "https://instagram.com/_kajiminami_?igshid=YmMyMTA2M2Y=",
  //   "original": "/common/instagram.png",
  //   "webp": "/common/instagram.webp"
  // },
  // {
  //   "name": "Facebook",
  //   "url": "https://www.facebook.com/profile.php?id=100091765301920",
  //   "original": "/common/facebook.png",
  //   "webp": "/common/facebook.webp"
  // },
  // {
  //   "name": "Twitter",
  //   "url": "https://twitter.com/kajiminamistaff",
  //   "original": "/common/twitter.png",
  //   "webp": "/common/twitter.webp"
  // }
];
