const env = {
  publicUrl: 'https://kajiminami.com',
  ga: {
    id: 'G-F9JK6HH5TN',
  },
  contact: {
    action:
      'https://docs.google.com/forms/u/0/d/e/1FAIpQLScRlaKJ-fJusE0Lm5fhow-mgg9sNOEnAPtYROq_yZubnb8LiQ/formResponse',
    name: 'entry.1168118489',
    kana: 'entry.525409081',
    email: 'entry.2095630878',
    context: 'entry.931508375',
  },
};

export default env;
